<template>
  <div class="view flex flex-d ai-c" style="position: relative;">
    <headers :styleSize="'min'" :times="headTime"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <div class="title-view flex ai-c">
      <img src="@/assets/speaial_activity_img/image/icon_title.png" class="icon" alt="">
      <img v-if="!titleImgTime" src="@/assets/speaial_activity_img/image/fourtitle10.png" class="title" alt="">
      <img v-else src="@/assets/speaial_activity_img/image/fourtitle11.png" class="title" alt="">
      <!-- <span class="time-hint">（当前数据统计截止时间11月10日，11日数据将于24:00后更新）</span> -->
    </div>
    <div class="number">{{ streamSalesFormatted }}</div>
    <div class="main flex flex-1 jc-sb">
      <div class="left">
        <SubTitleAndIcon>
          <p>直播电商平台GMV排名</p>
        </SubTitleAndIcon>
        <div class="ranking-view">
          <Rankind :list="rankingList"></Rankind>
        </div>
      </div>
      <div class="right">
        <SubTitleAndIcon>
          <p>11.1-11.11直播电商平台每日GMV</p>
        </SubTitleAndIcon>
        <div class="chart-view">
          <LineChart :data="echartData"></LineChart>
        </div>
      </div>
    </div>
    <Annotation hint="免责声明见第11页。"></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import Rankind from '@/components/Big/Rankind.vue'
import LineChart from '@/components/Big/LineChart.vue'
import SubTitleAndIcon from '@/components/Big/SubTitleAndIcon'
import toolMixin from '@/utils/tool'
import Annotation from '@/components/Big/Annotation'
export default {
  mixins: [toolMixin],
  components: {
    Headers,
    Rankind,
    LineChart,
    SubTitleAndIcon,
    Annotation,
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      headTime: '',
      rankingList: [],
      echartData: {},
      titleImgTime: false,
    }
  },
  watch: {
    data(newValue, oldValue) {
      this.init()
    },
  },
  computed: {
    streamSalesFormatted() {
      const formattedValue = (this.data.streamSales / 100000000).toFixed(2)
      const unit = '亿元'
      return formattedValue + unit
    },
  },
  mounted() {
    this.init()
    const currentDate = new Date() // 获取当前日期
    const currentTimestamp = currentDate.getTime() // 将日期转换为时间戳
    const targetDate = new Date('2023-11-10') // 获取目标日期
    const targetTimestamp = targetDate.getTime() // 将日期转换为时间戳
    if (currentTimestamp > targetTimestamp) {
      // 当前日期大于目标日期
      this.titleImgTime = true
    } else {
      // 当前日期小于或等于目标日期
      this.titleImgTime = false
    }
  },
  methods: {
    init() {
      if (Object.keys(this.data).length == 0) return
      // console.log('level4', this.data)
      let newValue = this.data
      this.rankingList = []
      let streamRankPlatform = [...newValue.streamRankPlatform]
      //设置左侧的图
      if (streamRankPlatform && streamRankPlatform.length != 0) {
        this.rankingList = streamRankPlatform.slice(0, 3).map((res) => {
          return {
            name: res.platform,
          }
        })
      }
      //设置右侧的图
      if (newValue.streamRankDate && newValue.streamRankDate.length != 0) {
        let dataX = [],
          dataY = []
        newValue.streamRankDate.forEach((item, index) => {
          dataX.push(this.timeDisposal(item.date))
          dataY.push((item.sales / 100000000).toFixed(2))
        })
        this.echartData = {
          dataX,
          dataY,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
