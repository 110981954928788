<!-- 城市排行榜 -->
<template>
  <div class='ranking'>
    <div :class="['ranking-item','ranking-hot'+index]" v-for="(item,index) in list" :key="index">
      <div class="ranking-item-title">
        <div class="level">NO{{ index+1 }}</div>
        <p>{{item.name}}</p>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    list: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.ranking {
  width: 100%;
  height: 100%;
  padding: rem(10) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: rem(40);
  .level {
    margin-right: rem(16);
  }
}

.ranking-item-title {
  font-family: 'Source Han Sans CN';
  display: flex;
  font-size: rem(20);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: rem(20);
}
@mixin ranking-style($color, $width, $color2) {
  .level {
    color: $color;
    padding: rem(2) rem(4);
    background: linear-gradient(to top, $color, $color) left top no-repeat,
      //上左
      linear-gradient(to right, $color, $color) left top no-repeat,
      //左上
      linear-gradient(to left, $color, $color) right top no-repeat,
      //上右
      linear-gradient(to bottom, $color, $color) right top no-repeat,
      //上右
      linear-gradient(to left, $color, $color) left bottom no-repeat,
      //下左
      linear-gradient(to bottom, $color, $color) left bottom no-repeat,
      //左下
      linear-gradient(to top, $color, $color) right bottom no-repeat,
      //下右
      linear-gradient(to left, $color, $color) right bottom no-repeat; //右下
    background-size: 0.5px 10px, 10px 0.5px, 0.5px 10px, 10px 0.5px;
  }
  .line {
    width: $width;
    height: rem(12);
    background: linear-gradient(-90deg, $color2, $color);
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: rem(-6);
      width: rem(8);
      height: rem(24);
      background: $color;
    }
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: rem(-6);
      width: rem(8);
      height: rem(24);
      background: $color;
    }
  }
}
.ranking-hot0 {
  @include ranking-style(#d44c3a, 100%, #f6e67b);
}
.ranking-hot1 {
  @include ranking-style(#f27200, 90%, #f6e67b);
}
.ranking-hot2 {
  @include ranking-style(#eec900, 80%, #f6e67b);
}
.ranking-hot3 {
  @include ranking-style(#66c6af, 70%, #f6ec7b);
}
.ranking-hot4 {
  @include ranking-style(#7ba1f6, 60%, #f6ec7b);
}
.ranking-hot5 {
  @include ranking-style(#ffd800, 50%, #f6ec7b);
}
.ranking-hot6 {
  @include ranking-style(#ffd800, 40%, #f6ec7b);
}
@media screen and (max-height: 900px) {
  .ranking-item-title {
    font-family: 'Source Han Sans CN';
    display: flex;
    font-size: rem(16);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: rem(18);
  }
}
</style>