<!--  -->
<template>
  <div ref="lineRef" class="chart-view"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: '',
  props: {
    data: {
      type: Object,
    },
    languageType: {
      type: String,
      default: 'ZN',
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {
    data: {
      handler(val) {
        this.init(val.dataX, val.dataY)
      },
    },
  },
  methods: {
    //右侧的图
    init(dataX, dataY) {
      let that = this
      var chartDom = this.$refs['lineRef']
      var myChart = echarts.init(chartDom)
      let unit = {
        name: '(亿元)',
        nameTextStyle: {
          fontSize: 20,
          color: '#fff',
          align: 'center',
        },
        nameGap: 20,
      }
      if (this.languageType == 'EN') {
        unit.name = '(Hundred million yuan)'
        unit.nameTextStyle.align = 'left'
      }
      var option = {
        grid: {
          right: '0%',
          left: '8%',
          top: '14%',
          bottom: '10%',
        },
        tooltip: {
          show: true,
          formatter: function (data) {
            return (
              data.name +
              '<br>' +
              data.value +
              (that.$root.language == 'ZN' ? '亿' : '')
            )
          },
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,//x轴是否从起点开始
          data: dataX,
          //刻度轴标签
          axisLabel: {
            textStyle: {
              fontSize: this.transformFontSize(22),
              color: '#FFF',
            },
          },
        },
        yAxis: {
          type: 'value',
          ...unit,
          scale: true,
          splitNumber: 5,
          //隐藏y轴轴线
          axisLine: {
            show: false,
          },
          axisTick: {
            //隐藏刻度线
            show: false,
          },
          //刻度轴标签
          axisLabel: {
            textStyle: {
              fontSize: this.transformFontSize(22),
              color: '#FFF',
            },
            // formatter: that.$root.language == 'ZN' ? '{value} 亿' : '{value} ',
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(208, 217, 226, 0.3)',
            },
          },
        },
        series: [
          {
            data: dataY,
            type: 'line',
            smooth: true,
            lineStyle: {
              //折线样式
              normal: {
                width: 4,
                color: 'rgba(246, 230, 123, 1)',
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(242, 114, 0, 1)', // 渐变起始颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(242, 114, 0, 0.2)', // 渐变结束颜色
                  },
                ],
              },
            },
            symbol: 'circle', //折线点设置为实心点
            symbolSize: 10, //折线点的大小
            itemStyle: {
              normal: {
                color: '#F27200', //折线点的颜色
                label: {
                  color: '#FFF',
                  show: true,
                  fontSize: this.transformFontSize(20),
                  formatter: function (data) {
                    return (
                      data.value + (that.$root.language == 'ZN' ? '亿' : '')
                    )
                  },
                },
              },
            },
          },
        ],
      }
      option && myChart.setOption(option)
    },
    transformFontSize(fontsize) {
      const width = window.screen.width
      const ration = width / 1920
      return parseInt((fontsize * ration).toString())
    },
  },
  created() {},
  mounted() {
    this.init(this.data.dataX, this.data.dataY)
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.chart-view {
  height: 100%;
  width: 100%;
}
</style>